table {
   border-collapse: collapse;
   width: 100%;
   margin: 0 auto !important;
   --table-layout: fixed;
   font-size: $default-font-size;

   &.smaller {
      width: 80%;
      font-size: 2.1rem;
      margin: 3rem auto !important;

      @include respond(phone) {
         width: 90%;
      }
      @include respond(small-phone) {
         width: 100%;

         td {
            padding: 1rem;

            input,
            select {
               width: 65%;
            }
         }
      }

      td:last-child {
         display: flex;
         justify-content: center;
      }
   }

   &.final-grades {
      width: 70%;

      &.small {
         width: 50%;
      }

      @include respond(small-phone) {
         width: 90%;
         &.small {
            width: 70%;
         }
      }
   }

   &.search tr:hover {
      background-color: lighten($light-color, 15%);
   }

   .option-input {
      appearance: none;
      position: relative;
      display: inline-block;
      outline: none;
      border: none;
      border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      background: inherit;
      color: $dark-color;
      cursor: pointer;
      z-index: 1000;
      @include easeOut;

      &:hover {
         background-color: lighten($light-color, 9%);
      }

      &:checked {
         opacity: 0.7;

         &::before {
            position: absolute;
            content: "✔";
            display: inline-block;
            text-align: center;
            font-size: 1.8rem;
            margin-left: 0.4rem;
         }

         &::after {
            display: none;
         }
      }
   }

   input[type="number"],
   input[type="text"],
   select,
   textarea {
      border: none;
      color: inherit;
      width: 100%;
      font-size: inherit;
      text-align: center;
      background-color: inherit;
   }

   select {
      text-align-last: center;
      font-size: 1.9rem;
   }

   textarea {
      text-align: start;
   }

   input[type="number"]:focus,
   input[type="text"]:focus,
   select:focus,
   textarea:focus {
      outline: none;
      border-bottom: 2px solid rgba($primary-color, 0.4);
   }

   th,
   td {
      padding: 1rem;
      text-align: -webkit-center;

      @include respond(small-phone) {
         padding: 0.5rem;
      }
   }

   th {
      background-color: lighten($primary-color, 10%);
      color: $white-color;
      opacity: 0.65;

      &.inherit {
         background-color: inherit;
      }

      &.blank {
         background-color: $white-color;
         opacity: 1;
      }

      &.small {
         width: 20%;
      }
   }

   td {
      &.paid {
         color: $success-color;
      }
      &.debt {
         color: $danger-color;
      }
   }

   tr.sticky {
      td:first-child {
         z-index: 1800 !important;
      }
      td {
         position: sticky;
         bottom: 0;
         left: 0;
         z-index: 1000 !important;
         background-color: inherit;
      }
   }

   tr:nth-child(odd) {
      background-color: lighten($secondary-color, 28%);
   }
   tr:nth-child(even) {
      background-color: $white-color;
   }
}
