.wrapper {
   position: relative;
   overflow-x: auto;
   white-space: nowrap;
   max-width: 100%;

   &.both {
      overflow-y: auto;
      max-height: 70vh;

      table th {
         opacity: 1;
         z-index: 1100;
      }

      &.smaller {
         max-height: 65vh;
      }
   }

   &::-webkit-scrollbar {
      height: 1rem;
      width: 1rem;

      &-track {
         box-shadow: inset 0 0 4px $secondary-color;
         border-radius: 10px;
      }

      &-thumb {
         box-shadow: inset 0 0 5px $primary-color;
         background: lighten($light-color, 5%);
         border-radius: 10px;

         &:hover {
            background: darken($light-color, 5%);
         }
      }
   }

   table {
      th.fit {
         width: 4rem !important;
      }

      &.stick th,
      &.stick td:first-child {
         position: sticky;
         left: 0;
         top: 0;
      }

      &.stick th:first-child {
         z-index: 2999;
         opacity: 1;
      }
      &.stick td:first-child {
         z-index: 1799;
         background-color: $white-color;
      }

      &.stick td:not(:first-child) {
         z-index: -1;
      }

      .btn {
         padding: 0.5rem 1rem;
      }
   }
}
