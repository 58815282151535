@include respond(small-phone) {
   .hide-sm {
      display: none !important;
   }
}

@include respond(phone) {
   .hide-md {
      display: none;
   }
}

@media only screen and (min-width: 40.65em) {
   .show-md {
      display: none;
   }
}

@media only screen and (min-width: 31em) {
   .show-sm {
      display: none;
   }
}
