//Buttons
.btn {
   &,
   &:link,
   &:visited {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      min-width: max-content;
      background: $light-color;
      color: $black-color;
      padding: 0.8rem 1.8rem;
      border: none;
      cursor: pointer;
      font-size: $default-font-size;
      border-radius: 0.64rem;
      opacity: 0.7;
      @include shortTrans;

      svg {
         font-size: 2.4rem;
      }

      /* @include respond(phone) {
			font-size: 2rem;
		} */

      &.btn-primary {
         @include background-hover($primary-color);
      }
      &.btn-dark {
         @include background-hover($dark-color);
      }
      &.btn-secondary {
         @include background-hover($secondary-color);
      }
      &.btn-mix-secondary {
         @include background(lighten($secondary-color, 20%));
         &:hover {
            @include background(lighten($light-color, 10%));
         }
      }
      &.btn-light {
         @include background-hover($light-color);
      }
      &.btn-print-blank {
         @include background-hover($secondary-color);
         color: $cream-color !important;
      }
      &.btn-danger {
         @include background-hover($danger-color);
      }
      &.btn-success {
         @include background-hover($success-color);
      }

      &.btn-black {
         @include background($black-color);
         transition: all 0.1s;

         &:hover,
         &:active,
         &:focus {
            background-color: #c2010e;
            transform: none;
         }
      }
   }

   &:hover {
      background-color: darken($light-color, 20%);
      color: $white-color;
      transform: translateY(-0.5rem);
      box-shadow: 0 1rem 2rem rgba($black-color, 0.2);
   }

   &:active,
   &:focus {
      outline: none;
      transform: translateY(-0.1rem);
      box-shadow: 0 0rem 1rem rgba($black-color, 0.2);
   }

   &-center {
      margin: 3rem auto 0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 70%;

      @include respond(phone) {
         margin-top: 2rem;
      }
   }

   &-right {
      margin-top: 3rem;
      display: flex;
      justify-content: flex-end;
      margin-right: -1rem;
      align-items: center;

      @include respond(phone) {
         margin-top: 1.5rem;
      }

      & > *:not(:last-child) {
         margin-right: 3rem !important;

         @include respond(small-phone) {
            margin-right: 1.5rem !important;
         }
      }
   }

   &-icon {
      font-size: 3rem;
      background: inherit;
      border: none;
      justify-self: center;
      align-self: center;
      border-radius: 50%;
      @include shortTrans;

      &:hover:not(.text-dark) {
         cursor: pointer;
         color: lighten($primary-color, 20%);
         transform: translateY(-0.3rem) scale(1.05);
      }

      &:active,
      &:focus {
         outline: none;
      }

      &:active {
         transform: translateY(-0.1rem);
      }
   }

   &-text {
      &,
      &:link,
      &:visited {
         font-size: $default-font-size;
         color: $primary-color;
         display: inline-block;
         text-decoration: none;
         //border-bottom: 1px solid $primary-color;
         padding: 0.5rem 1rem;
         transition: all 0.2s;
         backface-visibility: hidden;
         border: none;
         background-color: inherit;
         cursor: pointer;
      }

      &:hover {
         transform: translateY(-2px);
      }

      &:active {
         border-radius: 50%;
         box-shadow: 0 0.5rem 1rem rgba($black-color, 0.15);
         transform: translateY(0);
      }
   }

   &-end {
      display: flex;
      align-items: center;
      width: 100%;

      .form-input {
         margin-right: -3.5rem;
         z-index: 1;
      }

      &.name {
         align-items: baseline;
      }
   }

   &-cancel {
      border: none;
      padding: 0.4rem;
      margin-right: 1rem;
      color: $secondary-color;
      cursor: pointer;
      font-size: 2rem;
      border-radius: 50%;
      background-color: inherit;
      z-index: 10;
      display: flex;
      @include easeOut;

      &:hover {
         background-color: lighten($secondary-color, 20%);
         color: $black-color;
         transform: scale(1.2);
      }

      &:active,
      &:focus {
         outline: none;
         transform: translateY(-0.1rem);
         box-shadow: 0 0rem 1rem rgba($black-color, 0.2);
      }
   }
}
