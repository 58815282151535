.container {
   overflow: hidden;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 3.5rem 0;

   @include respond(tab-port) {
      padding: 2rem;
   }
   @include respond(phone) {
      padding: 2.3rem 2rem;
   }
   @include respond(small-phone) {
      padding: 1rem;
   }

   h1,
   h2 {
      color: $dark-color;
      padding: 3rem;
      display: flex;
      align-items: center;

      @include respond(phone) {
         padding: 1rem;
      }
   }

   h1 {
      font-size: 6.5rem;
      line-height: 7rem;

      @include respond(phone) {
         font-size: 5rem;
         line-height: 5.5rem;
      }
   }

   h2 {
      font-size: 4.5rem;
      line-height: 6rem;

      @include respond(phone) {
         font-size: 3.5rem;
         line-height: 4.5rem;
      }
   }

   .inner-container {
      padding: 6rem 5rem 4rem;
      background-color: $white-color;

      width: 75%;

      @include respond(tab-land) {
         width: 80%;
      }

      @include respond(tab-port) {
         width: 90%;
      }

      @include respond(phone) {
         width: 95%;
         padding: 4.5rem 3rem 2rem;
      }
   }
}

.row {
   max-width: $max-width;
   display: flex;
   margin: 0 auto;
   justify-content: space-evenly;
   align-items: center;

   /* &:not(:last-child) {
         margin-bottom: $horizontal-grid;
     } */

   @include respond(phone) {
      flex-direction: column;
   }
}
