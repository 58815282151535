@import "../../../style/abstract.scss";

.not-found {
   display: flex;
   padding: 3rem;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 80%;
   height: 70vh;

   .heading-primary {
      margin-top: -15rem;
      margin-left: -10rem;
      display: inline-block;

      @include respond(tab-port) {
         margin-left: 0;
         margin-top: -10rem;
         font-size: 5rem;
      }
   }

   .heading-secondary {
      margin-top: -2rem;
      text-align: center;

      @include respond(tab-port) {
         font-size: 3rem;
         line-height: 4rem;
      }
      @include respond(small-phone) {
         margin-top: 0;
      }
   }
}
