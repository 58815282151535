//Margin and Padding
@each $space in $spaceamounts {
   .m-#{$space} {
      margin: #{$space}rem !important;
   }

   .my-#{$space} {
      margin: #{$space}rem 0 !important;
   }

   .mt-#{$space} {
      margin: #{$space}rem 0 0 0 !important;
   }

   .mb-#{$space} {
      margin: 0 0 #{$space}rem 0 !important;
   }
   .ml-#{$space} {
      margin: 0 0 0 #{$space}rem !important;
   }

   .p-#{$space} {
      padding: #{$space}rem !important;
   }

   .py-#{$space} {
      padding: #{$space}rem 0 !important;
   }

   .pt-#{$space} {
      padding: #{$space}rem 0 0 0 !important;
   }

   .pb-#{$space} {
      padding: 0 0 #{$space}rem 0 !important;
   }
}

.text-right {
   text-align: end !important;
}

.text-left {
   text-align: start;
}

.text-center {
   text-align: center;
}

.text-moved-right {
   margin-left: 2rem;
}

.indentation {
   margin-left: 3rem;
}

//Backgrounds
.bg {
   &-primary {
      @include background($primary-color);
   }
   &-secondary {
      @include background($secondary-color);
   }
   &-lighter-secondary {
      @include background(lighten($secondary-color, 15%));
   }
   &-lightest-secondary {
      @include background(lighten($secondary-color, 28%));
   }
   &-light {
      @include background($light-color);
   }
   &-lighter {
      @include background(lighten($light-color, 15%));
   }
   &-dark {
      @include background($dark-color);
   }
   &-white {
      @include background($white-color);
   }
}

.round-img {
   border-radius: 50%;
   object-fit: cover;

   width: 30rem;
   height: 30rem;

   @include respond(phone) {
      width: 25rem;
      height: 25rem;
   }
}
