body {
   font-family: "Dancing Script", cursive;
   font-family: "Courgette", cursive;
   font-size: $default-font-size;
   line-height: 1.6;
   background-color: $plain-bg-color;
   color: $black-color;
}

.fancy-heading {
   font-family: "Dancing Script", cursive;
}

.light-font {
   font-weight: 100;
}

.posted-date {
   color: lighten($secondary-color, 8%);
   font-size: 1.3rem;
   text-align: end;
   padding: 1.3rem;
}

//Text Styles
.heading-primary {
   font-size: 7rem;
   line-height: 6.5rem;
   margin-bottom: 1.6rem;
}
.heading-secondary {
   font-size: 4.8rem;
   line-height: 6rem;
   margin-bottom: 1.6rem;

   @include respond(small-phone) {
      font-size: 4rem;
   }
}
.heading-tertiary {
   font-size: 2.5rem;
   line-height: 3rem;
   margin-bottom: 1.6rem;

   @include respond(small-phone) {
      font-size: 2rem;
      line-height: 2.3rem;
   }
}
.paragraph {
   font-size: $default-font-size;
   margin-bottom: 1rem;

   @include respond(tab-port) {
      font-size: 1.8rem;
   }
}

.text {
   &-primary {
      color: $primary-color;
   }

   &-lighter-primary {
      color: lighten($primary-color, 30%);
   }

   &-secondary {
      color: $secondary-color;
   }

   &-dark {
      color: $dark-color;
   }

   &-light {
      color: $light-color;
   }

   &-danger {
      color: $danger-color;
   }

   &-white {
      color: $white-color;
   }
}

button,
a {
   line-height: inherit;
}
button,
input,
select,
textarea {
   font-family: "Dancing Script", cursive;
   font-family: "Courgette", cursive;
}
