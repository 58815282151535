//FONT SIZES
$default-font-size: 1.6rem;
//sizes
$max-width: 114rem;
$horizontal-grid: 6rem;
$vertical-grid: 3rem;
//for padding and margin
$spaceamounts: (0, 1, 2, 3, 4, 5);

$primary-color: #14548c;
$secondary-color: #a6abab;
$dark-color: #0a2455;
$light-color: #aed8f2;
$danger-color: #c2010e;
$success-color: #05a6a6;
$cream-color: #ebeae1;
$white-color: #fff;
$black-color: #333;
$plain-bg-color: rgb(210, 219, 230);
