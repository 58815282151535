.form {
   width: 60%;
   margin: 0 auto;
   padding: 1rem 0;
   text-align: left;
   font-size: $default-font-size;

   @include respond(tab-port) {
      width: 70%;
   }
   @include respond(phone) {
      width: 80%;
   }
   @include respond(small-phone) {
      width: 90%;
   }

   .border,
   &.border {
      border: 1px $secondary-color solid;
      padding: 1.6rem;

      margin: 0 auto;
   }

   &.bigger {
      width: 90%;
   }

   &.smaller {
      width: 50%;

      @include respond(small-phone) {
         width: 75%;
      }
   }

   &-group {
      margin: 1rem 0;

      .two-in-row {
         display: inline-flex;
         width: 100%;

         .form-input {
            min-width: 50%;
         }
         .form-label {
            width: 100%;
         }
      }
   }

   &-input {
      display: block;
      width: 100%;

      padding: 0.96rem;
      font-size: inherit;
      border-radius: 2px;
      background-color: rgba($secondary-color, 0.1);
      border: none;
      //para que cuando se haga focus no se agrade el bloque entero
      border-bottom: 3px solid transparent;

      @include respond(small-phone) {
         -webkit-appearance: none;
         -moz-appearance: none;
      }

      &:focus {
         outline: none;
         box-shadow: 0 1rem 2rem rgba($black-color, 0.1);
         border-bottom: 3px solid $success-color;

         &:invalid {
            border-bottom: 3px solid $danger-color;
         }
      }

      &.center {
         text-align: center;
         text-align-last: center;
      }
   }

   &-label,
   &-label-show {
      font-size: 1.5rem;
      margin-left: 1rem;
      margin-top: 0.3rem;
      display: block;
      transition: all 0.3s;
      color: $dark-color;
   }

   &-input:placeholder-shown + &-label,
   .lbl {
      //ambos asi se puede animar y que aparezca de a poco con transition
      opacity: 0;
      visibility: hidden;
      transform: translateY(-4rem);
   }

   .radio-group,
   .checkbox-group {
      display: flex;
      align-items: center;
   }

   &-radio,
   &-checkbox {
      position: absolute;
      opacity: 0;
      z-index: 1;
   }

   .radio-lbl,
   .checkbox-lbl {
      position: relative;
      margin: 0 2rem 0.5rem 0;
      padding: 0.5rem 1.5rem 0.7rem 2.3rem;
      line-height: 2rem;
      z-index: 2;
      cursor: pointer;
      &:before {
         box-sizing: border-box;
         content: " ";
         position: absolute;
         top: 0.5rem;
         left: 0;
         display: block;
         width: 1.8rem;
         height: 1.8rem;
         border: 2px solid $secondary-color;
         background-color: lighten($light-color, 15%);
         border-radius: 0.5rem;
         z-index: 1;
      }
   }

   &-checkbox + .checkbox-lbl::before {
      padding-top: 0.35rem;
   }

   &-checkbox:disabled + .checkbox-lbl {
      opacity: 0.5;
      cursor: not-allowed;
   }

   &-radio + .radio-lbl::before {
      border-radius: 1em;
   }

   &-radio:disabled + .radio-lbl {
      opacity: 0.5;
      cursor: not-allowed;
   }

   &-radio:checked + .radio-lbl,
   &-checkbox:checked + .checkbox-lbl {
      color: $primary-color;
      &::before {
         top: 0;
         width: 100%;
         height: 2em;
         z-index: -1;
      }
   }

   .radio-lbl,
   .radio-lbl::before,
   .checkbox-lbl,
   .checkbox-lbl::before {
      transition: 0.3s all ease;
   }

   .upl-img {
      display: flex;
      justify-content: flex-end;
      align-content: center;

      .fileUpload {
         @include background($primary-color);
         opacity: 0.7;
         border-radius: 15px;
         position: relative;
         overflow: hidden;
         padding: 1.2rem 1.5rem;
         @include easeOut;

         .upload {
            position: absolute;
            top: 0;
            right: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;

            &::-webkit-file-upload-button {
               visibility: hidden;
            }
         }

         &:hover,
         &:active,
         &:focus {
            background-color: $light-color;
            color: $dark-color;
         }

         &.success {
            background-color: $success-color;
            color: $white-color;
         }

         span {
            display: flex;
            align-items: center;

            & > * {
               margin-right: 0.5rem;
               font-size: 2rem;
            }
         }
      }
   }
}
